/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import useWindowSize from 'hooks/useWindowSize';
import 'react-perfect-scrollbar/dist/css/styles.css';
import FullscreenOverlay from './FullscreenOverlay';
import Rating from './Rating';
const PerfectScrollbar = dynamic(() => import('react-perfect-scrollbar'));

const PERFECT_SCROLLBAR_OPTIONS = Object.freeze({
  minScrollbarLength: 50,
  swipeEasing: true
});

const MOBILE_VIEWPORT_WIDTH = 1100;

interface ReviewCardFooterProps {
  store?: string;
  country?: string;
  fullName?: string;
}

interface ReviewCardProps extends ReviewCardFooterProps {
  active?: boolean;
  rating: number;
  message: string;
}

interface ReviewCardContentProps extends ReviewCardFooterProps {
  message: string;
}

const ReviewCardMobileContent = ({
  message,
  fullName,
  country,
  store
}: ReviewCardContentProps) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [hasOverflow, setHasOverflow] = useState(false);

  const toggleOverlayVisibility = () => setIsOverlayVisible(!isOverlayVisible);

  const contentElement = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();

  useEffect(() => {
    if (!width || !contentElement?.current) return;

    setHasOverflow(
      contentElement?.current?.scrollHeight >
        contentElement?.current?.clientHeight
    );
  }, [message, width]);

  return (
    <>
      <ReviewCardFullscreenOverlay
        fullName={fullName}
        store={store}
        country={country}
        isVisible={isOverlayVisible}
        onToggleVisibility={toggleOverlayVisibility}>
        {message}
      </ReviewCardFullscreenOverlay>
      <div
        ref={contentElement}
        className="relative my-[20px] h-[105px] overflow-hidden px-[20px]"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 5
        }}>
        {message}
      </div>
      {hasOverflow && (
        <span
          className="mt-[-20px] px-[20px] pb-[10px] font-medium text-brand-blue25"
          onClick={toggleOverlayVisibility}>
          More
        </span>
      )}
    </>
  );
};

interface ReviewCardFullscreenOverlayProps extends ReviewCardFooterProps {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  onToggleVisibility: (visibility: boolean) => void;
}

const ReviewCardFullscreenOverlay: FC<ReviewCardFullscreenOverlayProps> = ({
  isVisible,
  onToggleVisibility,
  children,
  store,
  fullName
}) => (
  <FullscreenOverlay
    title="Review"
    isVisible={isVisible}
    onToggleVisibility={onToggleVisibility}>
    <div className="text-[14px] leading-[15px] text-brand-black100">
      {children}
    </div>
    <div className="pt-[16px] pb-[60px]">
      <ReviewCardFooter store={store} fullName={fullName} />
    </div>
  </FullscreenOverlay>
);

const ReviewCardContent = ({ message }: ReviewCardContentProps) => {
  const perfectScrollbarInstance = useRef<any>(null);

  useEffect(() => {
    if (!perfectScrollbarInstance?.current) return;
    // fixes swiper usage inside swiper always visible scroll bar issue
    perfectScrollbarInstance.current?.updateScroll?.();
  });

  /*
  Perfect scroll bar removed for performance reasons
  <PerfectScrollbar
      // @ts-ignore
      ref={perfectScrollbarInstance}
      options={PERFECT_SCROLLBAR_OPTIONS}
      className="my-[20px] mr-[10px]">
      <div className="h-[92px] pl-[20px] pr-[10px]">
        <div className="text-[14px] leading-[15px] text-brand-black100">
          {message}
        </div>
      </div>
    </PerfectScrollbar>
  */
  return (
  
      <div className="my-[20px] mr-[10px] h-[98px] pl-[20px] pr-[10px] text-[14px] leading-[15px] text-brand-black100">
          {message}
      </div>
  );
};

const ReviewCardFooter = ({ store, fullName }: ReviewCardFooterProps) => (
  <div className="px-[20px] text-brand-grey300">
    <span className="font-medium">{fullName} </span>
    <span>- {store}</span>
  </div>
);

const ReviewCard = ({
  rating,
  message,
  fullName,
  store,
  country
}: ReviewCardProps) => {
  const { width } = useWindowSize();

  return (
    <div className="flex w-full select-none flex-col justify-between rounded-[8px] bg-white py-[20px] text-[14px] transition-shadow duration-1000 ease-in-out shop:hover:shadow-card">
    
      <div className="flex px-[20px] mx-[-2px]">
       <img src="/images/icon_stars5.png" alt="Rating Stars" width="84" height="15" />
      </div>

      {width > MOBILE_VIEWPORT_WIDTH ? (
        <ReviewCardContent message={message} />
      ) : (
        <ReviewCardMobileContent
          store={store}
          country={country}
          fullName={fullName}
          message={message}
        />
      )}
      <ReviewCardFooter store={store} fullName={fullName} />
      
    </div>
  );
};

export default ReviewCard;
