import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Caret from 'components/Icons/Caret';
import ReviewCard from 'components/Others/ReviewCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const CUSTOMER_REVIEWS = Object.freeze([
  {
    country: 'AU',
    store: ' Newtown',
    fullName: 'Scania Icao',
    review:
      "Absolutely love my new glasses - can't wait to come back for my next pair! Both Alice and Jonathan are wonderful.",
    rating: 5
  },
  {
    country: 'AU',
    store: ' Newtown',
    fullName: 'Alexandra Mavros',
    review:
      "Broke my glasses today, but lucky for me the shop was open. They replaced my frame, no hassle, in less than 1 min, under warranty. Definitely a brand that goes above and beyond for their customers. Exceptional service! Best glasses and sunnies I've owned.",
    rating: 5
  },
  {
    country: 'AU',
    store: ' Newtown',
    fullName: 'Adrian Faccioni',
    review:
      "The glasses are amazing value, light and with the fantastic warranty on the frames you don't have to wrap them in cotton wool after each time you use them.",
    rating: 5
  },
  {
    country: 'AU',
    store: ' Newtown',
    fullName: 'Lucy Birss',
    review:
      "Absolute best place to get your eyes tested and purchase glasses. Great service. Excellent value for money. 10 year warranty. I'm looking forward to seeing more colours in the future so I can mix and match frames and arms even more. Very happy.",
    rating: 5
  },
  {
    country: 'AU',
    store: 'West End',
    fullName: 'Ellonie Norris',
    review:
      'Clean, friendly & professional!! Many frames to choose from. Vibrant and welcoming - I had a thorough check up to Recheck prescription and the Optometrist himself was experienced and knowledgeable. Handy location also. Would recommend to young and old',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Newtown North',
    fullName: 'Michael Huntington',
    review:
      "Great customer service. Very caring and attentive eye test. Good looking frames. The lenses are clear and light. I'm a big fan of Dresden.",
    rating: 5
  },
  {
    country: 'AU',
    store: 'Geelong',
    fullName: 'Cico Lobbert',
    review:
      "Professional, very affordable (my health fund covered the lot), and super friendly. Helpful above and beyond. I'll be back in the future no question.",
    rating: 5
  },
  {
    country: 'AU',
    store: 'Fitzroy',
    fullName: 'james connolly',
    review:
      'Amazing service from the whole team. Super happy with the speed and friendliness of the test and fitting.',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Fitzroy',
    fullName: 'nikki',
    review:
      'Very impressed! Excellent customer service. Excellent value for money. Love my new glasses. Thank you Dresden vision. Will definitely be coming back.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Hamilton',
    fullName: 'Supriya Ansilio',
    review:
      'Had an eye exam here and bought myself a couple pairs of new specs. Loved how fun it was to customize your own glasses. The price points are great, love how the frames are made of sustainable materials, and overall had a great experience. I have a small prescription and was able to grab my glasses within 20 minutes of picking out the frames I wanted. Direct billing was also much much appreciated.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Hamilton',
    fullName: 'F G',
    review:
      'Quality and affordable prescription eyeglasses. Frame flatters my face. I’m happy with my purchase. Shopped online and One week turnaround.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Hamilton',
    fullName: 'Amy MacLean',
    review:
      "This is your sign to check out Dresden! So thankful we have one in Hamilton. You can customize your frames, which in itself is really cool, but their glasses are also super affordable, sustainable and (usually) ready to take home the same day. Just bought my second pair and couldn't be happier.",
    rating: 5
  },
  {
    country: 'CA',
    store: 'Hamilton',
    fullName: 'April Hoffmann',
    review:
      'Picked this place because of the modular glasses. The ability to interchange almost every aspect offers a variety of options. The assistant there picked a perfect colour for my hinge pieces and I am so greatful. The direct billing for my insurance provider was amazing. I have already recommended them to my family and friends.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Little Italy',
    fullName: 'Max Vecchiarino',
    review: 'Great service! Great glasses! Great prices!',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Little Italy',
    fullName: 'Colin McCallum',
    review:
      'Amazing place! Great looking glasses, look good on any face and great price. Plus good warranty as just got free replacement after sitting on them.',
    rating: 5
  },
 /* {
    country: 'CA',
    store: 'Little Italy',
    fullName: 'Andres Kasekamp',
    review: 'Easy, quick, professional, stylish and cheap.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Little Italy',
    fullName: 'David Murray',
    review:
      'Great experience! Had an appointment with the optometrist and got a pair of glasses with my new prescription while I waited. I also love the focus on sustainability so I got frames made with recycled materials and they look great! I will definitely go back.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Riverside ',
    fullName: 'Emma Fox',
    review:
      'I have had my eye exams and purchased four frames for myself, and three for my school aged daughters here. The quality, price, and epic customer service cannot be beat. I have sent my entire extended family and all my friends to Dresden for their eyewear needs and everyone has come away super happy. Highly recommend!',
    rating: 5
  },
/*  {
    country: 'CA',
    store: 'Riverside ',
    fullName: 'Jenn Soong',
    review:
      'Great place for affordable prescription glasses! I experienced friendly service, and appreciated that they made the process so quick and easy.',
    rating: 5
  },
  {
    country: 'CA',
    store: 'Riverside ',
    fullName: 'The Advancing Low-Lives',
    review:
      'Can’t say anything except positive things about this place. Say good bye to overpriced prescription glasses. Plus the durability of these are great! Ideal for anyone who requires glasses while working a labour intense job. Style is an absolute bonus!',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Brunswick',
    fullName: 'MY Gan',
    review:
      'Brilliant concept and superb service! I highly recommend Dresden Brunswick for optometry services and affordable glasses',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Brunswick',
    fullName: 'NECDET AÇAN',
    review:
      'Australian made glasses with many colour combination, very cheap, and service is excellent',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Brunswick',
    fullName: 'Samuel Khusunawi',
    review:
      'Thanks for an awesome service. Looking forwards to my new pair of glasses soon. Bring on the colour for summer. Definitely, I will get more glasses from here. Highly recommended. Friendly service.',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Brunswick',
    fullName: 'D Munro',
    review:
      'Super easy, super cheap, super quick. One style of Australian made glasses with many colour combinations and sizes means no hassle. Sick of the colour of the frames or arms? Just change them! Prescriptions are made on the spot and staff are helpful 👍 Definitely recommend.',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Rozelle',
    fullName: 'Kimberley Pegg',
    review:
      "I love my Dresden glasses i have prescription (2) and sunglasses (2). They are fun and easy to wear, i love the bright colours and I coordinate them with what i am wearing. They bounce, don't seem to scratch and the customer service is outstanding. I also love that people actually stop me and ask me what sort of glasses are they, last place i was asked was in the Apple shop, how cool is that by 2 different! Keep up the great work - glasses don't need to cost you a fortune, Dresden are sustainably-made and also Australian-made.",
    rating: 5
  },
  {
    country: 'AU',
    store: 'Rozelle',
    fullName: 'O Roldan',
    review:
      'Amazing brand with reasonably-priced frames and lenses. My partner walked in to get a new script and walked out with a new pair. Our optometrist had amazing bedside manners and the staff were genuinely friendly and helpful. Takes most health funds.',
    rating: 5
  },
  {
    country: 'AU',
    store: 'Pitt Street',
    fullName: 'Joel Aq',
    review:
      'Had a fantastic experience at this store, everyone was super friendly and the glasses were fantastic!',
    rating: 5
  }*/
]);

const DEFAULT_MIN_VIEWPORT_WIDTH = 1024;
const LG_MIN_VIEWPORT_WIDTH = 1280;
const TABLET_MIN_VIEWPORT_WIDTH = 768;

const SWIPER_BREAKPOINTS = Object.freeze({
  [TABLET_MIN_VIEWPORT_WIDTH]: {
    slidesPerView: 2,
    slidesPerGroup: 1,
    speed: 200
  },
  [DEFAULT_MIN_VIEWPORT_WIDTH]: {
    slidesPerGroup: 1,
    slidesPerView: 2,
    spaceBetween: 0,
    speed: 1200
  },
  [LG_MIN_VIEWPORT_WIDTH]: {
    slidesPerGroup: 3,
    slidesPerView: 3,
    spaceBetween: 0,
    speed: 1200
  }
});

const FILTER_REVIEWS_BY_COUNTRY_COUNTRIES = Object.freeze(['ca']);

const HomepageReviews = () => {
  const router = useRouter();

  const customerReviews = useMemo(() => {
    const isFilterableLocale = FILTER_REVIEWS_BY_COUNTRY_COUNTRIES.includes(
      router.locale as string
    );

    if (isFilterableLocale) {
      return CUSTOMER_REVIEWS.filter(
        (review) => review.country.toLowerCase() === router.locale
      );
    }

    return CUSTOMER_REVIEWS.filter(
      (review) =>
        !FILTER_REVIEWS_BY_COUNTRY_COUNTRIES.includes(
          review.country.toLowerCase()
        )
    );
  }, [router.locale]);

  return (
    <section className="bg-brand-grey250 py-[40px] px-4 sm:pt-[88px] sm:pb-[78px]">
      <div className="mx-auto lg:max-w-[1244px]">
        <span className="mb-[22px] block text-center text-[24px] font-semibold text-brand-black100 sm:mb-[30px] md:text-[36px]">
          Customer reviews on Google
        </span>
        <div className="relative mx-auto sm:px-1 lg:max-w-[700px] shop:max-w-[980px] xl:max-w-[1100px] 2xl:max-w-none">
          <Swiper
            key={router.locale}
            className="reviews-swiper"
            modules={[Pagination, Navigation]}
            /*loop={true}*/
            lazy={true}
            /*loopFillGroupWithBlank={true}*/
            breakpoints={SWIPER_BREAKPOINTS}
            speed={200}
            navigation={{
              nextEl: '[data-reviews-slider-next]',
              prevEl: '[data-reviews-slider-previous]'
            }}
            slidesPerView={1}
            spaceBetween={0}
            allowTouchMove={true}
            slidesPerGroup={1}
            simulateTouch={false}
            pagination={{
              clickable: true,
              bulletClass: 'review-swiper-bullet',
              bulletActiveClass: '!bg-brand-black100',
              el: '[data-reviews-slider-pagination]',
              horizontalClass: 'review-swiper-pagination-horizontal',
              renderBullet: (_, classList) => {
                return `<span data-ignore-global-swiper-bullet-style class="review-swiper-bullet cursor-pointer transition-all duration-200 h-[10px] w-[10px] mx-[4px] rounded-full bg-brand-grey500 ${classList}"></span>`;
              }
            }}>
            {customerReviews.map((review, index) => (
              <SwiperSlide className="!h-auto" key={index}>
                <div className="flex h-full w-full p-[10px]">
                  <ReviewCard
                    rating={review.rating}
                    message={review.review}
                    fullName={review.fullName}
                    country={review.country}
                    store={review.store}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            data-reviews-slider-pagination
            className="mx-auto mt-[30px] flex justify-center"></div>
          <div
            data-reviews-slider-previous
            className="transition-color absolute left-[-68px] top-[100px] z-10 hidden h-[42px] w-[42px] rotate-90 transform cursor-pointer items-center justify-center rounded-full bg-white text-brand-black100 shadow-card duration-500 hover:text-brand-orange lg:flex">
            <Caret className="mt-[3px]" fill="currentColor" />
          </div>
          <div
            data-reviews-slider-next
            className="rotate absolute right-[-68px] top-[100px] hidden h-[42px] w-[42px]  -rotate-90 transform cursor-pointer items-center justify-center rounded-full bg-white text-brand-black100 shadow-card duration-500 hover:text-brand-orange lg:flex">
            <Caret className="mt-[3px]" fill="currentColor" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageReviews;
